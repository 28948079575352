import axios from 'axios'

// const urlApi = "https://staging.vendeae.com.br/api/"; //Homologação
const urlApi = "https://api.vendeae.com.br/api/"; //Produção
// const urlApi = "http://localhost:8000/api/"; //Local
const auth = axios.create({
    baseURL: urlApi,
});
const authNotInterceptor = axios.create({
    baseURL: urlApi,
});

auth.interceptors.request.use(async function (config:any) {
    let token = localStorage.getItem('token');
    let user:any = localStorage.getItem('user');
    config.params = { ...config.params }; 
    config.headers = {
        'Authorization': 'Bearer ' + token,
    }
    if (user !== null) {
        config.params = {...config.params, i: JSON.parse(user).companies[0].id}
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

authNotInterceptor.interceptors.request.use(async function (config:any) {
    let token = null;
    try {
        token = await localStorage.getItem('token');
    } catch (error) {
        return error;
    }
    config.params = {...config.params, token: token}
    return config;
}, function (error) {
    return Promise.reject(error);
});

auth.interceptors.response.use((response) => {
    return response;
},async (error) => {
    if (error.code === 'ERR_NETWORK') {
        return Promise.reject(error);
    }
    console.log(error);
    
    if (error.response.status === 401) {
        let newToken:any;
        newToken = await refreshToken.refreshToken();
        const requestConfig:any = error.config;
        requestConfig.params = { ...requestConfig.params, }
        requestConfig.headers = {
            'Authorization': 'Bearer ' + newToken,
        }
        return axios(requestConfig);
    }
    return Promise.reject(error);
})

const refreshToken = {
    refreshToken: async() => {
        authNotInterceptor.defaults.headers.common['Autorization'] = 'Barrer '+localStorage.getItem('token');
        return await authNotInterceptor.post('refresh')
        .then(async (response) => {
            try {
                await localStorage.setItem('token', response.data.token);
            } catch (error) {
                return error;
            }
            return response.data.token;
        }).catch((error) => {
            return error;
        });
    },
}

export default auth;