import React from 'react'
import {
	CButton,
	CCard,
	CCardBody,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilFactory, cilInfo, cilLockLocked, cilUser } from '@coreui/icons'
import { t } from "i18next"
import Mask from '../../../components/Mask'

const Register = () => {
	const [user, setUser] = React.useState({
		name: '',
		document: '',
		company_name: '',
		email: '',
		password: '',
		password_confirmation: '',
	})
	function saveNewClient(): void {
		throw new Error('Function not implemented.')
	}

	return (
		<div className="bg-light min-vh-100 d-flex flex-row align-items-center">
			<CContainer>
				<CRow className="justify-content-center">
					<CCol md={9} lg={7} xl={6}>
						<CCard className="mx-4">
							<CCardBody className="p-4">
								<CForm>
									<h1>{t('register')}</h1>
									<p className="text-medium-emphasis">{t('create_acount')}</p>
									<CInputGroup className="mb-3">
										<CInputGroupText>
											<CIcon icon={cilUser} />
										</CInputGroupText>
										<CFormInput placeholder={t("name")} autoComplete="name" />
									</CInputGroup>
									<CInputGroup className="mb-3">
										<CInputGroupText>
											<CIcon icon={cilInfo} />
										</CInputGroupText>
										<CFormInput placeholder={t("document")} autoComplete="document" value={user.document}
										onChange={(e: any) => setUser({ ...user, document: Mask.format_document(e.target.value) })}
										/>
									</CInputGroup>
									{user.document && user.document.length >= 15 &&
										<CInputGroup className="mb-3">
											<CInputGroupText>
												<CIcon icon={cilFactory} />
											</CInputGroupText>
											<CFormInput placeholder={t("company_name")} autoComplete="company_name" />
										</CInputGroup>
									}

									<CInputGroup className="mb-3">
										<CInputGroupText>@</CInputGroupText>
										<CFormInput placeholder={t("email")} autoComplete="email" />
									</CInputGroup>
									<CInputGroup className="mb-3">
										<CInputGroupText>
											<CIcon icon={cilLockLocked} />
										</CInputGroupText>
										<CFormInput
											type="password"
											placeholder={t("password")}
											autoComplete="new-password"
										/>
									</CInputGroup>
									<CInputGroup className="mb-4">
										<CInputGroupText>
											<CIcon icon={cilLockLocked} />
										</CInputGroupText>
										<CFormInput
											type="password"
											placeholder={t("password_confirmation")}
											autoComplete="new-password"
										/>
									</CInputGroup>
									<div className="d-grid">
										<CButton color="primary" onClick={() => saveNewClient()}>{t('create_acount')}</CButton>
									</div>
								</CForm>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
			</CContainer>
		</div>
	)
}

export default Register
