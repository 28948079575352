import auth from "./api";

const authApi = {
    login: async (credencials: any) => {        
        return await auth.post('login', credencials)
            .then(async (response) => {
                console.log(response);
                
                if (!response) {
                    return { error: '404' };
                }
                if (response.data.token) {
                    try {
                        await localStorage.setItem('token', response.data.token);
                    } catch (error) {
                        console.log(error);
                    }
                }
                return response.data;
            }).catch((error) => {
                console.log(error);
                
                return { error: error.response ? error.response.data.error : error.name };
            });
    },
    me: async () => {
        return await auth.get('user/profile')
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.log(error.response.data);
                return { error: error.response.data };
            });
    },
    verifyUser: async (data: any) => {
        return await auth.post('verificaUsuario', data)
            .then((response) => {
                return response.data;
            }).catch((error) => {
                return { ...error };
            });
    },
    createNewUser: async (data: any) => {
        return await auth.post('criaNovoUsuario', data)
            .then((response) => {
                return response.data;
            }).catch((error) => {
                console.log(error.response.data);

                return { ...error };
            });
    }
}
export default authApi;