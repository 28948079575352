import React from 'react'

const Dashboard = React.lazy(() => import('./Pages/Dashboards'))
const Products = React.lazy(() => import('./Pages/Products'))
const ProductsCreate = React.lazy(() => import('./Pages/Products/Create'))

const Clients = React.lazy(() => import('./Pages/Clients'))
const ClientCreate = React.lazy( () => import( './Pages/Clients/Create' ) )

const ClientCategory = React.lazy(() => import('./Pages/ClientCategory'))
const ClientCategoryCreate= React.lazy(() => import('./Pages/ClientCategory/create'))

const routes = [
  { path: '/', exact: true, name: 'home' },
  { path: '/dashboard', name: 'dashboards', element: Dashboard },
  { path: '/products', name: 'products', element: Products },
  { path: '/products/create', name: 'new_product', element: ProductsCreate },
  { path: '/products/:id', name: 'edit_product', element: ProductsCreate },

  { path: '/clients', name: 'clients', element: Clients },
  { path: '/clients/create', name: 'new_client', element: ClientCreate },
  { path: '/clients/:id',name: 'edit_client',element: ClientCreate },
  
  { path: '/client_category', name: 'client_category', element: ClientCategory },
  { path: '/client_category/create', name: 'new_client_category', element: ClientCategoryCreate },
  { path: '/client_category/:id', name: 'edit_client_category', element: ClientCategoryCreate },
]

export default routes
