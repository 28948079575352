import React, { Suspense, useContext } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CAlert, CButton, CContainer, CSpinner } from '@coreui/react'
import { AuthContext } from '../Context/authContext'

// routes config
import routes from '../routes'
import Loading from './Loading'

const AppContent = () => {
    const { alert, setAlert, loading } = useContext(AuthContext)
    return (
        <CContainer lg>
            <Suspense fallback={<CSpinner color="primary" />}>
                {alert.visible && <>
                    <CAlert color={alert.color} dismissible visible={alert.visible} onClose={() => setAlert({ ...alert, visible: false })}>
                        {alert.message}
                    </CAlert>
                </>}
                {loading ?
                    <Loading />
                    : <>
                        <Routes>
                            {routes.map((route, idx) => {
                                return (
                                    route.element && (
                                        <Route
                                            key={idx}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}
                                            element={<route.element />}
                                        />
                                    )
                                )
                            })}
                            <Route path="/" element={<Navigate to="dashboard" replace />} />
                        </Routes>
                    </>}
            </Suspense>
        </CContainer>
    )
}

export default React.memo(AppContent)
