import * as React from "react";
import {
    Routes,
    Route,
    useLocation,
    Navigate,
    BrowserRouter,
} from "react-router-dom";
import { AuthContext, AuthProvider } from "./Context/authContext";
import Login from "./Pages/Auth/Login";
import './scss/style.scss'
import DefaultLayout from "./layout/DefaultLayout";
import Page404 from "./views/pages/page404/Page404";
import Register from "./Pages/Auth/Register";

export default function App() {
    return (
        <AuthProvider>
            <BrowserRouter>
                <Routes>
                    <Route>
                        {/* <Route path="*" element={<DefaultLayout />} /> */}

                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route
                            path="*"
                            element={
                                <RequireAuth>
                                    <DefaultLayout />
                                </RequireAuth>
                            }
                        />
                        {/* <Route path="*" element={<Page404 />} /> */}
                    </Route>
                </Routes>
            </BrowserRouter>
        </AuthProvider>
    );
}

function RequireAuth({ children }: { children: JSX.Element }) {
    const { isLogged } = React.useContext(AuthContext)
    let location = useLocation();
    let loginValidation = isLogged();
    
    if (!loginValidation) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
}

function ProtectedPage() {
    return <h3>Protected</h3>;
}