const Mask = {
    cpf: (value:any) => {
        value = value.replace(/\D/g,"");
        value = value.replace(/(\d{3})(\d)/,"$1.$2");
        value = value.replace(/(\d{3})(\d)/,"$1.$2");
        value = value.replace(/(\d{3})(\d{1,2})$/,"$1-$2");
        return value;
    },
    cnpj: (value:any) => {
        value = value.replace(/\D/g,"");
        value = value.replace(/^(\d{2})(\d)/,"$1.$2");
        value = value.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3");
        value = value.replace(/\.(\d{3})(\d)/,".$1/$2");
        value = value.replace(/(\d{4})(\d)/,"$1-$2");
        return value;
    },
    phone: (value:any) => {
        if (!value) return ""
        value = value.replace(/\D/g,'')
        value = value.replace(/(\d{2})(\d)/,"($1) $2")
        value = value.replace(/(\d)(\d{4})$/,"$1-$2")
        return value
    },
    zip_code: (value:any) => {
        value = value.replace(/\D/g,"")                
        value = value.replace(/^(\d{5})(\d)/,"$1-$2") 
        return value
    },
    money: (value:any) => {
        value = value.replace(/\D/g,"");
        var beforeDot = value.substring(0, value.length-2);
        var afterDot = value.substring(value.length-2, value.length);
        value = parseFloat(beforeDot + "." + afterDot);
        value = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2}).format(value)
        return value
    },
    format_money: (value:any) => {
        if (value == null) {
            return '0,00'
        }
        value = parseFloat(value);
        value = value.toFixed(2).split('.');
        value[0] = value[0].split(/(?=(?:...)*$)/).join('.');
        return value.join(',');
    },
    number: (value:any) => {
        if (!value) return ""
        value = value.replace(/\D/g,"");
        var beforeDot = value.substring(0, value.length-2);
        var afterDot = value.substring(value.length-2, value.length);
        value = parseFloat(beforeDot + "." + afterDot);
        return value;
    },
    format_document: (value:any) => {
        value = value.replace(/\D/g,"");
        if (value.length <= 11) {
            value = value.replace(/(\d{3})(\d)/,"$1.$2");
            value = value.replace(/(\d{3})(\d)/,"$1.$2");
            value = value.replace(/(\d{3})(\d{1,2})$/,"$1-$2");
        } else {
            value = value.replace(/^(\d{2})(\d)/,"$1.$2");
            value = value.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3");
            value = value.replace(/\.(\d{3})(\d)/,".$1/$2");
            value = value.replace(/(\d{4})(\d)/,"$1-$2");
        }
        return value;
    }
}
export default Mask;
