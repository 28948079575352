import React from 'react'
import CIcon from '@coreui/icons-react'
import {
    cilSpeedometer,
    cilStorage,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
    {
        component: CNavTitle,
        name: 'admin',
    },
    // {
    //     component: CNavItem,
    //     name: 'dashboards',
    //     to: '/dashboard',
    //     icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    //     // badge: {
    //     //     color: 'info',
    //     //     text: 'NEW',
    //     // },
    // },
    {
        component: CNavGroup,
        name: 'registers',
        to: '/',
        icon: <CIcon icon={cilStorage} customClassName="nav-icon" />,
        items: [
            // {
            //     component: CNavItem,
            //     name: 'clients',
            //     to: '/clients',
            // },
            {
                component: CNavItem,
                name: 'products',
                to: '/products',
            },
            {
                component: CNavItem,
                name: 'client_category',
                to: '/client_category',
            },
        ],
    },
    // {
    //     component: CNavItem,
    //     name: 'Docs',
    //     href: 'https://coreui.io/react/docs/templates/installation/',
    //     icon: <CIcon icon={cilDescription} customClassName="nav-icon" />,
    // }
]

export default _nav
