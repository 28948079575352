import React from "react";
import { CSpinner } from "@coreui/react";

const Loading = () => {
  return (
    <div className="loading ">
        <CSpinner color="primary" />
        Aguarde...
    </div>
  );
};

export default Loading;