
import React, { useEffect } from "react";
import { fakeAuthProvider } from "../auth";
import authApi from "../services/authApi";
import { Navigate, redirect, useNavigate } from "react-router-dom";

interface AuthContextType {
    user: any;
    setUser: any;
    signin: any;
    signout: any;
    isLogged: any;
    alert: any;
    alertMessage: any;
    loading: boolean;
    setLoading: any;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [user, setUser] = React.useState<any>(null);
    const [alert, setAlert] = React.useState<any>({
        message: '',
        color: '',
        visible: false
    });

    useEffect(() => {
        let localStorageUser = localStorage.getItem('user');
        let localStorageToken = localStorage.getItem('token');
        if (localStorageUser === null && localStorageToken !== null) {
            getMe();
        }
    }, [user]);

    async function getMe() {
        let response = await authApi.me();
        localStorage.setItem('user', JSON.stringify(response))
        setUser(response)
    };

    async function signin(credentials: any) {
        let response = await authApi.login(credentials);        
        if (response.token) {
            localStorage.setItem('token', response.token);
            await getMe();
            return true;
        } else {
            return {error: response};
        }
    };

    function signout() {
        localStorage.clear();
    };

    function isLogged() {
        let token = localStorage.getItem('token');
        return token !== null ? true : false;
    };

    function alertMessage(message:string, color:string) {
        setAlert({
            message: message,
            color: color,
            visible: true
        });
        setTimeout(() => {
            setAlert({
                message: '',
                color: '',
                visible: false
            });
        }, 10000);
    }
    const value = { user, setUser, signin, signout, isLogged, alert, alertMessage, setAlert, loading, setLoading };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}