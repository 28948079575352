import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {
    CButton,
    CCard,
    CCardBody,
    CCardGroup,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CInputGroupText,
    CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { AuthContext } from '../../../Context/authContext'
import Imagem from '../../../assets/images/icon.png'
import { CImage } from '@coreui/react'

export default function Login() {
    const { signin, isLogged } = React.useContext(AuthContext);
    const [loading, setLoading] = React.useState<any>(false);

    interface UserIntarface {
        email: any;
        password: any;
    }
    const [credentials, setCredentials] = React.useState<UserIntarface>({email: '', password: ''});
    const handleEmailChange = (event:any) => {
        const updatedUserData = { ...credentials };
        updatedUserData.email = event.target.value;
        setCredentials(updatedUserData);
    };    
    const handlePasswordChange = (event:any) => {
        const updatedUserData = { ...credentials };
        updatedUserData.password = event.target.value;
        setCredentials(updatedUserData);
    };    

    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate('/products'); // Use navigate para navegar para outra rota
    };
    async function sigin() {
        setLoading(true)
        let response = await signin(credentials)
        if (typeof response.error === 'undefined') {
            handleNavigate()
        }
        setLoading(false)
    }
    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={8}>
                        <CCardGroup>
                            <CCard className="p-4">
                                <CCardBody>
                                    <CForm>
                                        <div className="text-center mb-3">
                                            <CImage src={Imagem} height={45} alt="Logo" className='me-3'/>
                                            <h1>VendeAe</h1>
                                        </div>
                                        {/* <p className="text-medium-emphasis">Sign In to your account</p> */}
                                        <CInputGroup className="mb-3">
                                            <CInputGroupText>
                                                <CIcon icon={cilUser} />
                                            </CInputGroupText>
                                            <CFormInput 
                                                placeholder="E-mail" 
                                                autoComplete="username" 
                                                value={credentials.email} 
                                                onChange={handleEmailChange}
                                            />
                                        </CInputGroup>
                                        <CInputGroup className="mb-4">
                                            <CInputGroupText>
                                                <CIcon icon={cilLockLocked} />
                                            </CInputGroupText>
                                            <CFormInput
                                                type="password"
                                                placeholder="Senha"
                                                autoComplete="current-password"
                                                value={credentials.password} 
                                                onChange={handlePasswordChange}
                                            />
                                        </CInputGroup>
                                        <CRow>
                                            <CCol xs={6}>
                                                <CButton color="primary" disabled={!loading ? false : true} className="px-4" onClick={() => {sigin()}}>
                                                    {!loading ? 'Entrar' : 'Aguarde...'}
                                                </CButton>
                                            </CCol>
                                            <CCol xs={6} className="text-right">
                                                <CButton color="link" className="px-0">
                                                    Esqueceu a senha?
                                                </CButton>
                                            </CCol>
                                        </CRow>
                                    </CForm>
                                </CCardBody>
                            </CCard>
                        </CCardGroup>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    )
}

